import "../Styles/index.scss";

import { Fancybox } from "@fancyapps/ui";
import Flickity from "flickity";
import { initializeMaps } from "@wagich/bluc-map";

initializeMaps();

Fancybox.bind(".is-zoomable");

let flickityElements = document.querySelectorAll(".block-slider");
for (let i = 0; i < flickityElements.length; i++) {
	let element = flickityElements[i];
	if (element.children.length > 0) {
		new Flickity(element, {
			wrapAround: true
		});
	}
}
